export const colors = {
  // White tones
  white: '#ffffff',
  offwhite: '#f7f7f7',
  backgroundWhite: '#FAFAFA',
  light: '#ffffff',
  beige: '#f0eee8',

  // Black tones
  black: '#151414',
  dark: '#50555b',
  darkmode: '#454647',
  darkmodeOffset: '#333333',

  // Grey tones
  lightGrey: '#f0f0f0',
  formGrey: '#BFBFBF',
  formDark: '#454647',
  grey: '#5B5D62',
  midGrey: '#454647',
  shuttleGrey: '#5f6871',
  borderLightGrey: '#DFDFDF',
  buttonGrey: '#C4C4C4',

  // Blue tones
  fountainBlue: '#489eb1',
  deepBlue: '#3a7e8d',
  blueOnDark: '#91D6E5',
  blueOnDark50: '#C8EBF2', // 50% of blueOnDark
  lagoonBlue: '#6793ae',
  lagoonGradientStep: '#50A7BA',
  lightBlue: '#B7CEDF',
  softBlue: '#8eb1c1',
  cartBlue: '#D2E0DF',
  cream: '#f5f9fa',

  // Green tones
  highlandGreen: '#67714d',
  highlandSecondaryGreen: '#a19d82',
  highlandHoverGreen: '#645f51',
  mossGreen: '#9BA483',
  orange: '#E0BD63',
  brightGreen: '#A1DD00',
  green: '#5BB263',
  lightGreen: '#D8E7E6',

  // Red tones
  deepRed: '#BC4838',
  orangutan: '#96757e',
  rust: '#AE4242',
  errorRed: '#DA4B4B',

  // Other
  transparent: 'transparent',
}
